<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import Multiselect from "vue-multiselect";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import gridSetup from "@/components/data/gridSetup";
import { typeOfRoles } from "@/data/data-type-of-roles";
import Repository from "@/app/repository/repository-factory";
import { roleService } from "@/app/service/roleService";

const UserAccountRepository = Repository.get("UserAccountRepository");
const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");

/**
 * Account List
 */
export default {
  page: {
    title: "Lista użytkowników",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      ...gridSetup.data(),
      header: {
        title: "Lista użytkowników",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista użytkowników",
          },
        ],
      },
      fieldsSelectOptions: [],
      reportArray: [],
      rf: {
        "Imię": "firstName",
        "Nazwisko": "lastName",
        "E-mail": "email",
        "Role": "roles",
        "Stanowisko": "position",
        "Aktywny": {
          field: "isActive",
          callback: (value) => {
            return value ? "Tak" : "Nie";
          },
        },
        "Utworzony": "createdAt",
      },
      rolesSelectOptions: typeOfRoles,
      companySelectOptions: [],
    };
  },
  created() {
    this.getStateGridFilters();
    this.getCompanies();
  },
  methods: {
    fieldsComputed(fieldList) {
      let fieldsComputed = [];
      let fieldsSelected = [];
      const selectedCount = this.filters.grid.fieldsSelected.length;
      const defaultField = [
        "firstName",
        "lastName",
        "email",
        "createdAt",
        "isActive",
      ];

      fieldsComputed.push({ key: "avatar", label: "" });

      // If selected is empty (eg. no save state)
      if (selectedCount === 0) {
        Object.entries(fieldList).forEach(([key, val]) => {
          if (defaultField.includes(val.key)) {
            fieldsSelected.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });

            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        });

        this.filters.grid.fieldsSelected = fieldsSelected;
      }

      // If selected is not empty
      if (selectedCount > 0) {
        Object.entries(this.filters.grid.fieldsSelected).forEach(
          ([key, val]) => {
            fieldsComputed.push({
              text: val.label,
              value: val.key,
              label: val.label,
              key: val.key,
              sortable: val.sortable,
            });
          }
        );
      }

      fieldsComputed.push({ key: "action", label: "Akcje", thClass: "last-col-sticky", tdClass: "last-col-sticky" });
      this.grid.fields = fieldsComputed;
    },
    getCompanies() {
      PaymentCompanyRepository.getAll()
        .then((response) => {
          let companies = [];
          let i;

          for (i = 0; i < response.data.length; i++) {
            companies.push({
              text: response.data[i].name,
              value: response.data[i].name,
            });
          }

          this.companySelectOptions = companies;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gridProvider(ctx) {
      // if (localStorage.getItem("gridUsersAccountsPaginationState") !== null) {
      //   ctx.currentPage = JSON.parse(
      //     localStorage.getItem("gridUsersAccountsPaginationState")
      //   );
      // }

      // if (localStorage.getItem("gridUsersAccountsSearchState") !== null) {
      //   this.filters.grid.filter = JSON.parse(
      //     localStorage.getItem("gridUsersAccountsSearchState")
      //   );
      // }

      const parameters =
        "?page=" +
        ctx.currentPage +
        "&maxItems=" +
        ctx.perPage +
        "&search=" +
        this.filters.grid.filter +
        "&orderBy=" +
        this.filters.grid.sortBy +
        "&orderDesc=" +
        this.filters.grid.sortDesc +
        // "&filterCompany=" +
        // this.filters.companies.map((e) => e.value).join(",") +
        "&filterApplicantCompany=" +
        this.filters.applicantCompanies.map((e) => e.text).join(",") +
        "&filterAcceptorCompany=" +
        this.filters.acceptantCompanies.map((e) => e.text).join(",") +
        "&filterRole=" +
        this.filters.typeOfRoles.map((e) => e.value).join(",");
      const promise = UserAccountRepository.getGrid(parameters);

      return promise
        .then((data) => {
          const fields = data.data.info.fields;
          this.grid.totalRows = data.data.info.countAllItems;

          // if (
          //   localStorage.getItem("gridUsersAccountsPaginationState") !== null
          // ) {
          //   this.grid.currentPage = JSON.parse(
          //     localStorage.getItem("gridUsersAccountsPaginationState")
          //   );
          // }

          let fieldsToSelect = [];

          Object.entries(fields).forEach(([key, val]) => {
            if (val.disabled !== true) {
              fieldsToSelect.push({
                text: val.label,
                value: key,
                label: val.label,
                key: key,
                sortable: val.sortable,
              });
            }
          });

          this.fieldsSelectOptions = fieldsToSelect;
          this.fieldsComputed(fieldsToSelect);

          this.reportArray = data.data.data || [];

          return this.reportArray;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    },
    refreshGrid(value, id) {
      this.$refs.table_account.refresh();
    },
    clearGridFiltersAndCols() {
      localStorage.removeItem("gridUsersAccountsPaginationState");
      localStorage.removeItem("gridUsersAccountsSearchState");
      this.grid.currentPage = 1;
      this.filters.typeOfRoles = [];
      this.filters.acceptantCompanies = [];
      this.filters.applicantCompanies = [];
      this.filters.companies = [];
      this.filters.grid.filter = "";
      this.filters.grid.filterOn = [""];
      this.filters.grid.perPage = 25;
      this.filters.grid.sortBy = "createdAt";
      this.filters.grid.sortDesc = true;
      this.filters.grid.fieldsSelected = [];
      this.saveStateGridFilters();
    },
    clearGridFilters() {
      this.grid.currentPage = 1;
      this.filters.typeOfRoles = [];
      this.filters.companies = [];
      this.$refs.table_account.refresh();
    },
    saveStateGridFilters() {
      localStorage.setItem(
        "gridUsersAccountsState",
        JSON.stringify(this.filters)
      );
      this.$refs.table_account.refresh();

      Vue.swal({
        icon: "success",
        toast: false,
        position: "top",
        title: "SUKCES!",
        text: "Filtry zostały zapisane!",
        showConfirmButton: false,
        timer: 3500,
      });
    },
    getStateGridFilters() {
      if (localStorage.getItem("gridUsersAccountsState") !== null) {
        this.filters = JSON.parse(
          localStorage.getItem("gridUsersAccountsState")
        );
      }
    },
    clearAllTypeOfRoles() {
      this.filters.typeOfRoles = [];
      this.$refs.table_account.refresh();
    },
    clearAllAcceptantCompanies() {
      this.filters.acceptantCompanies = [];
      this.$refs.table_account.refresh();
    },
    clearAllApplicantCompanies() {
      this.filters.applicantCompanies = [];
      this.$refs.table_account.refresh();
    },
    clearAllCompanies() {
      this.filters.companies = [];
      this.$refs.table_account.refresh();
    },
  },
  computed: {
    totalFiltersLength() {
      let f1 = this.filters.typeOfRoles ? this.filters.typeOfRoles.length : 0;
      // let f2 = this.filters.companies ? this.filters.companies.length : 0;
      let f3 = this.filters.acceptantCompanies ? this.filters.acceptantCompanies.length : 0;
      let f4 = this.filters.applicantCompanies ? this.filters.applicantCompanies.length : 0;
      return f1 + f3 + f4;
    },
    isAdmin() {
      return roleService.isAdmin();
    },
  },
  watch: {
    // "grid.currentPage": function () {
    //   localStorage.setItem(
    //     "gridUsersAccountsPaginationState",
    //     JSON.stringify(this.grid.currentPage)
    //   );
    // },
    // "filters.grid.filter": function () {
    //   localStorage.setItem(
    //     "gridUsersAccountsSearchState",
    //     JSON.stringify(this.filters.grid.filter)
    //   );
    // },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />

    <div class="row" v-if="isAdmin">
      <div class="col-12">
        <b-collapse id="collapse">
          <b-card class="shadow-sm">
            <div class="font-weight-bold" slot="header">Ustawienia kolumn i filtrów</div>
            <div class="form-group">
              <label class="typo__label">Wybierz widoczne kolumny tabeli</label>
              <multiselect v-model="filters.grid.fieldsSelected" :multiple="true" :options="fieldsSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz kolumny" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @input="fieldsComputed(fieldsSelectOptions)">
                <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
              </multiselect>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-12 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Rola użytkownika</label>
                  <multiselect v-model="filters.typeOfRoles" :multiple="true" :options="rolesSelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz rolę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych ról: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.typeOfRoles.length" @mousedown.prevent.stop="clearAllTypeOfRoles(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <!-- <div class="col-md-12 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Wybierz firmę</label>
                  <multiselect v-model="filters.companies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz firmę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" class="multiselect__single">Wybranych firm: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.companies.length" @mousedown.prevent.stop="clearAllCompanies(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div> -->
              <div class="col-md-12 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Akceptujący w spółce</label>
                  <multiselect v-model="filters.acceptantCompanies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz firmę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych firm: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.acceptantCompanies.length" @mousedown.prevent.stop="clearAllAcceptantCompanies(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
              <div class="col-md-12 col-lg-4">
                <div class="form-group">
                  <label class="typo__label">Wnioskujący w spółce</label>
                  <multiselect v-model="filters.applicantCompanies" :multiple="true" :options="companySelectOptions" :preserve-search="true" track-by="value" label="text" placeholder="Wybierz firmę" select-label="" deselect-label="Odznacz ✕" selected-label="Wybrane ✓" @close="refreshGrid">
                    <template slot="selection" slot-scope="{ values, search, isOpen }">
                      <span v-if="values.length && !isOpen" :data-search="search" class="multiselect__single">Wybranych firm: {{ values.length }}</span>
                    </template>
                    <template slot="clear" slot-scope="props">
                      <div class="multiselect__clear" v-if="filters.applicantCompanies.length" @mousedown.prevent.stop="clearAllApplicantCompanies(props.search)"></div>
                    </template>
                    <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                  </multiselect>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-left">
                <button type="button" class="btn btn-danger waves-effect waves-light mr-2" v-on:click="clearGridFiltersAndCols">
                  <i class="mdi mdi mdi-trash-can-outline mr-1" /> Usuń ustawienia
                </button>
                <button type="button" class="btn btn-dark waves-effect waves-light mr-2" v-on:click="saveStateGridFilters">
                  <i class="dripicons-pin mr-1" /> Zapisz ustawienia
                </button>
              </div>
              <div class="col text-right">
                <button type="button" class="btn btn-warning waves-effect waves-light" v-on:click="clearGridFilters">
                  <i class="mdi mdi-filter-remove-outline mr-1" /> Wyczyść filtry
                </button>
              </div>
            </div>
          </b-card>
        </b-collapse>
        <div class="card shadow-sm">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">Użytkownicy</div>
              <div>
                <i class="mdi mdi-format-columns mr-1"></i>Kolumny: <span class="badge badge-pill badge-soft-info font-size-12">{{ filters.grid.fieldsSelected.length + '/' + fieldsSelectOptions.length}}</span>
                <span class="ml-2" v-if="totalFiltersLength > 0"><i class="mdi mdi-filter-outline mr-1"></i>Aktywne filtry: <span class="badge badge-pill badge-soft-success font-size-12">{{ totalFiltersLength }}</span></span>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <b-form inline>
                  <b-form-select v-model="filters.grid.perPage" :options="grid.pageOptions" class="w-auto" />
                  <div class="ml-2 search-box">
                    <div class="position-relative">
                      <b-form-input v-model="filters.grid.filter" :debounce="350" placeholder="Szukaj..." :class="filters.grid.filter !='' ? 'border-success' : ''" />
                      <i class="ti-search search-icon" />
                    </div>
                  </div>
                </b-form>
              </div>
              <div class="col-md-6">
                <div class="text-right mt-2 mt-md-0 mb-2">
                  <b-link v-if="isAdmin" :to="{ name: 'Dodaj użytkownika' }" class="btn btn-dark waves-effect waves-light mb-2 mr-2">
                    <i class="mdi mdi-plus mr-1" /> Dodaj użytkownika
                  </b-link>

                  <download-excel class="btn btn-warning mb-2 mr-2" :stringifyLongNum="true" :data="reportArray" :fields="rf" worksheet="My Worksheet" name="filename.xls">
                    <i class="mdi mdi-file-excel mr-2"></i>Pobierz Excel
                  </download-excel>

                  <b-button v-b-toggle="'collapse'" class="waves-effect mb-2 collapsed" variant="success">
                    <i class="mdi mdi-filter-outline mr-1"></i>Filtry
                  </b-button>
                </div>
              </div>
            </div>
            <!-- End search -->

            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table id="table_account" ref="table_account" :items="gridProvider" :fields="grid.fields" responsive="sm" sticky-header="45vh" sort-icon-left :per-page="filters.grid.perPage" :current-page="grid.currentPage" :sort-by.sync="filters.grid.sortBy" :sort-desc.sync="filters.grid.sortDesc" :filter="filters.grid.filter" :filter-included-fields="filters.grid.filterOn">
                <template v-slot:table-busy>
                  <div class="text-center text-black">
                    <b-spinner class="align-middle" />
                    <strong> Ładowanie danych...</strong>
                  </div>
                </template>

                <template v-slot:cell(avatar)="row">
                  <b-link :to="{ name: 'Profil użytkownika', params: { id: row.item.userAccountId } }">
                    <div v-if="row.item.profileImage === null || row.item.profileImage === ''" class="avatar-xs">
                      <span class="avatar-title rounded-circle">{{
                        row.item.firstName.substring(0, 1).toLocaleUpperCase()
                      }}</span>
                    </div>
                    <div v-if="row.item.profileImage !== null && row.item.profileImage !== ''" class="avatar-xs">
                      <img :src="row.item.profileImage" class="rounded-circle avatar-xs">
                    </div>
                  </b-link>
                </template>

                <template v-slot:cell(email)="row">
                  <a :href="'mailto:' + row.item.email">{{ row.item.email }}</a>
                </template>

                <template v-slot:cell(roles)="row">
                  <span class="badge font-size-12 badge-soft-success mr-1" v-for="(role, index) in row.item.roles.split(', ')" :key="index">
                    {{ role }}
                  </span>
                </template>

                <template v-slot:cell(isActive)="data">
                  <span class="badge badge-pill badge-soft-success font-size-12" :class=" { 'badge-soft-danger': data.value == false,
                               'badge-soft-success': data.value == true }">
                    {{ data.value ? 'Tak' : 'Nie' }}</span>
                </template>

                <template v-slot:cell(createdAt)="data">
                  <span class="text-nowrap">
                    {{ data.value }}
                  </span>
                </template>

                <template v-slot:cell(action)="row">
                  <ul class="list-inline font-size-20 contact-links mb-0">
                    <li class="list-inline-item px-2" v-if="isAdmin">
                      <a v-b-tooltip.hover.topleft title="Przejście do profilu">
                        <b-link :to="{ name: 'Profil użytkownika', params: { id: row.item.userAccountId } }" class="grid-action-btn">
                          <i class="font-size-14 far fa-user" />
                        </b-link>
                      </a>
                    </li>
                    <li class="list-inline-item px-2" v-if="isAdmin">
                      <a v-b-tooltip.hover.topleft title="Edycja danych">
                        <b-link :to="{ name: 'Edycja użytkownika', params: { id: row.item.userAccountId } }" class="grid-action-btn">
                          <i class="font-size-14 far fa-edit" />
                        </b-link>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>

            <!-- Pagination -->
            <div class="row">
              <div class="col">
                <template v-if="grid.totalRows === 0">
                  <div class="d-flex justify-content-center mt-3">
                    <b>Nie znaleziono elementów</b>
                  </div>
                </template>
                <template v-if="grid.totalRows > 0">
                  <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                    Znaleziono {{ grid.totalRows }} rekordów.
                  </div>
                </template>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-if="grid.totalRows > 0" v-model="grid.currentPage" :total-rows="grid.totalRows" :per-page="filters.grid.perPage" first-number last-number />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
